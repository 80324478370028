import React from "react";
import * as styles from "./post.module.scss";
import { Link } from "gatsby";
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";

const Post = (props) => {
  
  deckDeckGoHighlightElement();

  let post = props.post;

  return (
    <React.Fragment>
      <div className={styles.posts}>
        <div className={styles.post}>
          <div className={styles.header}>
            <h1 className={styles.title}>
              <Link to={post.url}>{post.title}</Link>
            </h1>
            <p className={styles.date}>{post.frontmatter.date}</p>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: post.html,
            }}
          ></div>
          <ul className={styles.metas}>
            <li>
              categories:
              {post.categories.map((category, index) => {
                return (<span key={index}>
                  <Link to={"/categories/" + category.toLowerCase()}>
                    {category.toLowerCase()}
                  </Link>
                </span>)
              })}
            </li>
            <li>
              tags:
              {post.tags.map((tag, index) => {
                return <span key={index}><Link to={"/tags/" + tag.toLowerCase()}>{tag.toLowerCase()}</Link></span>;
              })}
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Post;
