import React from "react";
import { graphql } from "gatsby";
import Post from "../components/posts/post";

const PostTemplate = ({ data }) => {
  return (
    <React.Fragment>
      <Post post={data.allMarkdownRemark.nodes[0]} />
    </React.Fragment>
  );
};

export const query = graphql`
  query post($id: String!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        isFuture: { eq: false }
        isDraft: { eq: false }
        id: { eq: $id }
      }
    ) {
      nodes {
        frontmatter {
          title
          tags
          date
          categories
          author
          coverImage {
            childImageSharp {
              gatsbyImageData(
            width: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
            }
          }
        }
        title
        html
        isFuture
        url
        isDraft
        tags
        categories
        id
        excerpt(format: PLAIN, truncate: true, pruneLength: 500)
      }
    }
  }
`;

export default PostTemplate;
